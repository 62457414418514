@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Hind';
    src: url(/public/fonts/Hind-Light.ttf);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Hind';
    src: url(/public/fonts/Hind-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Hind';
    src: url(/public/fonts/Hind-Medium.ttf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Hind';
    src: url(/public/fonts/Hind-SemiBold.ttf);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Hind';
    src: url(/public/fonts/Hind-Bold.ttf);
    font-weight: 700;
    font-style: normal;
}